import React from "react";
import s from "@emotion/styled";
import { mediaQueriesMax } from "@src/styles/theme";
import { Button } from "@src/components/nessie-web";

export const Display1 = s("h2")`
  font-family: DojoDisplay !important;
  font-size: 69px;
  font-style: normal;
  font-weight: 800;
  line-height: 100%;
  margin: 0;
  letter-spacing: -0.9px;
  color: #2C2A50;
  ${mediaQueriesMax[0]} {
    font-size: 35px;
  }
`;

export const Body1 = s("p")`
  font-family: DojoText;
  font-size: 21px;
  font-style: normal;
  margin: 0;
  font-weight: 500;
  line-height: 130%;
`;

export const LightbulbIcon = () => (
  <svg width="20" height="30" viewBox="0 0 20 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.4236 16.021C18.1927 14.3308 19.297 11.9475 19.297 9.30511C19.297 4.01465 14.8742 -0.244791 9.52173 0.0200142C4.85102 0.251014 1.00297 4.05407 0.721267 8.72478C0.546608 11.5531 1.64522 14.1279 3.49322 15.9365C4.93556 17.345 5.93284 19.1198 6.34413 21.0579C6.41174 21.3847 6.1976 21.6946 5.87082 21.7453C5.77504 21.7622 5.67925 21.7735 5.58347 21.7904C5.36937 21.8242 5.18905 21.9876 5.13271 22.196C4.68198 23.875 4.68198 25.6385 5.13271 27.3118C5.18905 27.5203 5.36937 27.6837 5.58347 27.7175C6.0173 27.7907 6.44552 27.8471 6.87935 27.8922C7.09908 27.9147 7.27942 28.0612 7.35267 28.2696L7.60618 29.0303C7.66815 29.2218 7.83718 29.3683 8.03438 29.4021C9.33587 29.6275 10.6655 29.6275 11.967 29.4021C12.1698 29.3683 12.3332 29.2218 12.3952 29.0303L12.6487 28.2696C12.7163 28.0612 12.9023 27.9147 13.122 27.8922C13.5559 27.8415 13.9897 27.7851 14.4179 27.7175C14.632 27.6837 14.8122 27.5203 14.8685 27.3118C15.3193 25.6329 15.3193 23.8694 14.8685 22.1904C14.8122 21.9819 14.632 21.8185 14.4179 21.7847C14.3109 21.7678 14.2038 21.7509 14.0967 21.734C13.7699 21.689 13.5558 21.3734 13.6234 21.0523C14.0291 19.148 14.9925 17.3845 16.4236 16.021ZM11.5783 4.70202C11.3924 4.72456 11.2064 4.75271 11.0205 4.78652C8.30484 5.31049 6.12436 7.48527 5.60039 10.2066C5.56658 10.3925 5.53847 10.5784 5.51593 10.7643C5.38071 11.9757 3.66224 12.1616 3.34109 10.9841C3.08192 10.0375 3.02559 9.00651 3.21152 7.94166C3.70169 5.14712 5.94975 2.89909 8.74993 2.40328C9.81478 2.21736 10.8458 2.27369 11.7923 2.53286C12.9755 2.84273 12.7896 4.56117 11.5783 4.70202Z"
      fill="white"
    />
  </svg>
);

export const LockIcon = () => (
  <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.2515 12.199C21.0149 11.0046 20.0006 10.1257 18.7893 9.99608C18.5132 9.96791 18.2992 9.73127 18.2992 9.4552V7.60157C18.2992 3.45484 14.8229 0.102518 10.6311 0.310982C6.70971 0.502543 3.70119 3.88866 3.70119 7.81003V9.4552C3.70119 9.7369 3.49266 9.96791 3.21095 9.99608C1.99961 10.12 0.985535 11.0046 0.748901 12.199C0.0728037 15.602 0.0671585 19.1009 0.737622 22.5039L0.748901 22.5602C0.979901 23.749 1.94884 24.6561 3.14892 24.8082C8.3605 25.4843 13.6341 25.4843 18.8457 24.8082C20.0458 24.6505 21.0149 23.749 21.2459 22.5602L21.2571 22.5039C21.9332 19.1009 21.9276 15.602 21.2515 12.199ZM7.09846 9.64676C6.78294 9.66929 6.51826 9.4214 6.51826 9.10588V7.60157C6.51826 5.02676 8.69867 2.9534 11.3073 3.12805C13.6849 3.28581 15.4821 5.37045 15.4821 7.75932V9.10588C15.4821 9.4214 15.2173 9.66366 14.9018 9.64676C12.3045 9.47773 9.6958 9.47773 7.09846 9.64676Z"
      fill="white"
    />
  </svg>
);

export const SmileIcon = () => (
  <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.9999 0.627808C7.21916 0.627808 0.914551 6.93242 0.914551 14.7132C0.914551 22.4939 7.21916 28.7985 14.9999 28.7985C22.7807 28.7985 29.0853 22.4939 29.0853 14.7132C29.0853 6.93242 22.7807 0.627808 14.9999 0.627808ZM19.8735 8.96635C20.8087 8.96635 21.5637 9.72132 21.5637 10.6566C21.5637 11.5919 20.8087 12.3468 19.8735 12.3468C18.9382 12.3468 18.1832 11.5919 18.1832 10.6566C18.1832 9.72132 18.9382 8.96635 19.8735 8.96635ZM10.1377 8.96635C11.0729 8.96635 11.8279 9.72132 11.8279 10.6566C11.8279 11.5919 11.0729 12.3468 10.1377 12.3468C9.20238 12.3468 8.44741 11.5919 8.44741 10.6566C8.44741 9.72132 9.20238 8.96635 10.1377 8.96635ZM15.0056 23.1306L14.9661 23.0799L14.9267 23.1306C10.256 23.3503 7.55721 19.8515 7.79384 17.6091C8.02484 15.3667 10.1433 15.2541 11.4786 15.8569C13.7773 16.8936 16.3803 16.702 18.4537 15.8513C19.8115 15.2935 21.9017 15.3498 22.1384 17.5979C22.375 19.8403 19.6875 23.3447 15.0112 23.1306H15.0056Z"
      fill="white"
    />
  </svg>
);

export const PlayIcon = () => (
  <svg width="91" height="91" viewBox="0 0 91 91" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M87.3092 39.3406C65.996 21.8861 41.7815 8.87771 15.9429 1.01447C10.4322 -0.655256 4.75721 3.03378 3.6806 9.01373C-0.717062 33.1083 -0.717062 57.8823 3.6806 81.9768C4.77546 87.9567 10.4504 91.6651 15.9429 89.9759C41.7997 82.0933 66.0142 69.085 87.3092 51.6499C91.1047 48.5434 91.1047 42.4471 87.3092 39.3406Z"
      fill="white"
    />
  </svg>
);

export const Headline1 = s("h3")`
  font-family: DojoDisplay !important;
  font-size: 28px;
  font-style: normal;
  margin: 0;
  font-weight: 800;
  line-height: 120%; /* 33.6px */
  letter-spacing: -0.1px;
`;

export const Display2 = s("h2")`
  font-family: DojoDisplay !important;
  color: #2C2A50;
  font-size: 55px;
  font-style: normal;
  margin: 0;
  font-weight: 800;
  line-height: 100%; /* 55px */
  letter-spacing: -0.6px;
  ${mediaQueriesMax[0]} {
    font-size: 35px;
  }
`;

export const Display3 = s("h2")`
  font-family: DojoDisplay !important;
  color: #2C2A50;
  font-size: 44px;
  margin: 0;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 52.8px */
  letter-spacing: -0.3px;
  ${mediaQueriesMax[0]} {
    font-size: 35px;
  }
`;

interface UnderlineProps {
  backgroundImageUrl: string;
}

export const Underline = s("span")<UnderlineProps>`
  position: relative;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    background-image: url(${(props) => props.backgroundImageUrl});
    width: 100%;
    height: 15%;
    background-repeat: no-repeat;
    background-size: 100%;
  }
`;

export const DarkButton = s(Button)`
  background-color: #2C2A50;
  &:hover {
    background-color: #1A192D;
  }
`;
